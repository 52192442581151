<template>
  <el-main>
    <el-button style="margin-bottom: 15px" type="primary" class="el-icon-plus" size="small" @click="add">添加活动</el-button>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="活动标题：">
        <el-input v-model="title" size="small"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="15px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="title" label="活动标题" align="center"></el-table-column>
      <el-table-column prop="cate_name" label="抽奖次数" align="center">
        <template v-slot="{ row }">
          {{ row.frequency }}
        </template>
      </el-table-column>
      <el-table-column prop="content" label="已参与人次" align="center"></el-table-column>
      <el-table-column prop="is_open" label="开启关闭" align="center">
        <template v-slot="{ row }">
          <el-switch v-model="row.is_open" :active-value="1" :inactive-value="0" @change="isOpen(row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="start_time" label="活动时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.start_time) }}~{{ getDateformat(row.end_time) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button @click="edit(row)" type="text">编辑</el-button>
          <el-button @click="del(row)" type="text">删除</el-button>
          <el-button @click="see(row)" type="text">查看数据</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      title: '',
      page: 1,
      rows: 10,
      list: [],
      total: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getList();
    },
    search() {
      this.page = 1;
      this.getList();
    },
    clearSearch() {
      this.title = '';
      this.page = 1;
      this.rows = 10;
      this.getList();
    },
    add() {
      this.$router.push('/extension/gatherTweeters/addLuckyDraw');
    },
    edit(row) {
      this.$router.push({
        path: '/extension/gatherTweeters/editLuckyDraw',
        query: {
          id: row.id,
        },
      });
    },
    see(row) {
      this.$router.push({
        path: '/extension/gatherTweeters/luckyDrawData',
        query: {
          id: row.id,
          title: row.title,
          time: getDateformat(row.start_time) + ' ~ ' + getDateformat(row.end_time),
        },
      });
    },
    isOpen(row) {
      this.$axios
        .post(this.$api.serviceProvider.luckyDrawIsopen, {
          id: row.id,
          is_open: row.is_open,
        })
        .then(res => {
          if (res.code == 0) {
            console.log(res);
          }
        });
    },
    del(row) {
      this.$confirm('请确认是否删除此活动？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.serviceProvider.delLuckyDraw, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('删除成功');
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    getList() {
      this.$axios
        .post(this.$api.serviceProvider.luckyDrawList, {
          page: this.page,
          rows: this.rows,
          title: this.title,
        })
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total = res.result.total;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>