var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('el-button',{staticClass:"el-icon-plus",staticStyle:{"margin-bottom":"15px"},attrs:{"type":"primary","size":"small"},on:{"click":_vm.add}},[_vm._v("添加活动")]),_c('el-form',{staticClass:"el-form-search",attrs:{"label-width":"90px"}},[_c('el-form-item',{attrs:{"label":"活动标题："}},[_c('el-input',{attrs:{"size":"small"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('el-form-item',{attrs:{"label":" ","label-width":"15px"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.search}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":_vm.clearSearch}},[_vm._v("清空搜索条件")])],1)],1),_c('el-table',{attrs:{"data":_vm.list,"header-cell-style":{ 'background-color': '#F8F9FA' }}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"prop":"title","label":"活动标题","align":"center"}}),_c('el-table-column',{attrs:{"prop":"cate_name","label":"抽奖次数","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.frequency)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"content","label":"已参与人次","align":"center"}}),_c('el-table-column',{attrs:{"prop":"is_open","label":"开启关闭","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},on:{"change":function($event){return _vm.isOpen(row)}},model:{value:(row.is_open),callback:function ($$v) {_vm.$set(row, "is_open", $$v)},expression:"row.is_open"}})]}}])}),_c('el-table-column',{attrs:{"prop":"start_time","label":"活动时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.getDateformat(row.start_time))+"~"+_vm._s(_vm.getDateformat(row.end_time)))]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.edit(row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.del(row)}}},[_vm._v("删除")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.see(row)}}},[_vm._v("查看数据")])]}}])})],2),_c('Paging',{attrs:{"total":_vm.total,"page":_vm.page,"pageNum":_vm.rows},on:{"updatePageNum":_vm.updateData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }